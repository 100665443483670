import supabase from "../util/supabase.js";
import percentile from "percentile";

export const PUBLIC_URL_MB = 'www.merriam-webster.com';
export const DEV_URL_MB = 'dev.merriam-webster.com';
export const PUBLIC_URL_BRIT = 'www.britannica.com';
export const DEV_URL_BRIT = 'mendel-dev.dev-ext.britannica.com';


export function isServer() {
    return typeof window === 'undefined';
}

export function isDevEnv() {    
    const isDevEnv = process.env.NODE_ENV === 'development' || (!isServer() && window.location.href.indexOf(PUBLIC_URL_MB) === -1);

    return isDevEnv;
}

export function getPercentilesFromLength(length) {
  if (length < 10) {
    return [];
  } else if (length < 20) {
    return [10, 25];
  } else if (length < 50) {
    return [5, 10, 25];
  } else if (length < 70) {
    return [2, 5, 10, 25];
  } else {
    return [1, 2, 5, 10, 25];
  }
}

export async function fetchPercentiles(puzzleId) {
  const { data, error } = await supabase
    .from("tracking_events")
    .select()
    .eq("puzzle_id", puzzleId)
    .eq("event_type", "answer_submitted");
  const answerTimes = [];
  if (error) {
    return [];
  }
  for (const answer of data) {
    const answerData = answer.data;
    const timeSec = answerData["puzzle_time_sec"];
    if (timeSec && answerData["is_correct"]) {
      answerTimes.push(timeSec);
    }
  }
  const percentileTargets = getPercentilesFromLength(answerTimes.length);
  const percentileVals = percentile(percentileTargets, answerTimes);
  const percentileData = [];
  for (let i = 0; i < percentileTargets.length; i++) {
    percentileData.push({
      percentile: percentileTargets[i],
      value: percentileVals[i],
    });
  }
  return percentileData;
}